import React from 'react'
import {
  BASE_URL,
  positions,
  defaultWidgetProps,
  widgetPropTypes
} from './types'
import styles from './styles.module.css'

function generateScriptTag(widgetProps) {
  const script = document.createElement('script')
  const params = new URLSearchParams(widgetProps)
  script.src = `${BASE_URL}?${params.toString()}`
  script.async = true
  return script
}

const OpenTable = ({ customClassName, position, ...widgetProps }) => {
  const scriptRef = React.useRef(null)
  React.useEffect(() => {
    scriptRef.current = generateScriptTag(widgetProps)
    document.getElementById('open-table').appendChild(scriptRef.current)
  }, [])

  React.useEffect(() => {
    if (scriptRef && scriptRef.current) {
      const oldParams = new URL(scriptRef.current.src).search
      const newParams = '?' + new URLSearchParams(widgetProps).toString()
      if (oldParams !== newParams) {
        Array.from(
          document.getElementById('open-table').children
        ).forEach((element) => element.remove())
        scriptRef.current = generateScriptTag(widgetProps)
        document.getElementById('open-table').appendChild(scriptRef.current)
      }
    }
  }, [widgetProps, scriptRef])

  const combinedClassName = `${styles.openTableContainer} ${styles[position]} ${customClassName}`
  return <div className={combinedClassName} id='open-table' />
}

export default OpenTable

export { positions }

OpenTable.defaultProps = defaultWidgetProps
OpenTable.propTypes = widgetPropTypes
