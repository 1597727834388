import PropTypes from 'prop-types'

const EMBED_TYPE_BUTTON = 'button'
const EMBED_TYPE_STANDARD = 'standard'

const THEME_TYPE_STANDARD = 'standard'
const THEME_TYPE_TALL = 'tall'
const THEME_TYPE_WIDE = 'wide'

const LANG_EN_US = 'en-US'
const LANG_FR_CA = 'fr-CA'
const LANG_DE_DE = 'de-DE'
const LANG_ES_MX = 'es-MX'
const LANG_JA_JP = 'ja-JP'
const LANG_NL_NL = 'nl-NL'
const LANG_IT_IT = 'it-IT'

const OT_SOURCE_RESTAURANT = 'Restaurant website'
const OT_SOURCE_EMAIL = 'Email'
const OT_SOURCE_FACEBOOK = 'Facebook'
const OT_SOURCE_GOOGLE = 'Google'
const OT_SOURCE_INSTAGRAM = 'Instagram'
const OT_SOURCE_OTHER = 'Other'

const POSITION_TOP_LEFT = 'top-left'
const POSITION_TOP_RIGHT = 'top-right'
const POSITION_BOTTOM_LEFT = 'bottom-left'
const POSITION_BOTTOM_RIGHT = 'bottom-right'
const POSITION_UNSET = 'unset'

const embedTypes = [EMBED_TYPE_BUTTON, EMBED_TYPE_STANDARD]
const themeTypes = [THEME_TYPE_STANDARD, THEME_TYPE_TALL, THEME_TYPE_WIDE]
const languageTypes = [
  LANG_EN_US,
  LANG_FR_CA,
  LANG_DE_DE,
  LANG_ES_MX,
  LANG_JA_JP,
  LANG_NL_NL,
  LANG_IT_IT
]
const marketingTrackingTypes = [
  OT_SOURCE_RESTAURANT,
  OT_SOURCE_EMAIL,
  OT_SOURCE_FACEBOOK,
  OT_SOURCE_GOOGLE,
  OT_SOURCE_INSTAGRAM,
  OT_SOURCE_OTHER,
  PropTypes.string
]

export const BASE_URL = 'https://www.opentable.com/widget/reservation/loader'

export const positions = {
  POSITION_TOP_LEFT,
  POSITION_TOP_RIGHT,
  POSITION_BOTTOM_LEFT,
  POSITION_BOTTOM_RIGHT,
  POSITION_UNSET
}

export const defaultWidgetProps = {
  type: EMBED_TYPE_STANDARD,
  theme: THEME_TYPE_STANDARD,
  iframe: true,
  lang: LANG_EN_US,
  newtab: false,
  position: positions.POSITION_BOTTOM_LEFT
}

export const widgetPropTypes = {
  rid: PropTypes.string.isRequired,
  type: PropTypes.oneOf(embedTypes),
  theme: PropTypes.oneOf(themeTypes),
  iframe: PropTypes.bool,
  lang: PropTypes.oneOf(languageTypes),
  newtab: PropTypes.bool,
  ot_source: PropTypes.oneOf(marketingTrackingTypes),
  ot_campaign: PropTypes.string,
  customClassName: PropTypes.string,
  position: PropTypes.oneOf(Object.values(positions))
}
